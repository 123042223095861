.e5h-eventlogs .filter .MuiInputBase-root {
  height: 41px;
  width: 287px;
}

.e5h-eventlogs .filter .MuiFormControl-root + .MuiFormControl-root {
  margin-left: 10px;
}

.e5h-eventlogs .filter label {
  transform: translate(14px, 12px) scale(1);

  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.e5h-eventlogs .filter label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5h-eventlogs .filter label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5h-eventlogs .e5h-eventlogs-select .MuiSelect-select {
  display: flex;
}

.e5h-eventlogs-select {
  display: flex;
  align-items: center;
  justify-content: start;
}

.e5h-eventlogs-select + .e5h-eventlogs-select {
  margin-left: 5px;
}

.e5h-eventlogs-ext {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.e5h-eventlogs .refreshIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.e5h-eventlogs .refreshIcon {
  color: #0080ff;
  cursor: pointer;
}

.e5h-eventlogs .refreshIcon svg {
  width: 20px;
  height: 20px;
}

.e5h-eventlogs-refresh-popover {
  padding: 10px;
}